<template>
  <div class="container pt-4">
    <BaseHeader :title="$t('views.seller.my_account.text_1143')" class="mb-5">
      <div style="margin-top: -20px;">
        <Locales :showCountryListTab="showCountryListTab" @show-country-list="showCountryList('global')" />
      </div>
    </BaseHeader>
    <b-tabs class="mt-4" nav-class="TabHeader no-border"
      :active-nav-item-class="isHeaven ? 'TabHeader-heaven--active' : 'TabHeader--active'" :no-nav-style="true">
      <!-- Personal -->
      <b-tab :title="`${$t('views.seller.my_account.text_1567')} ${this.hasUserDocumentsApproved ? '✔️' : ''}`" active>
        <b-container fluid class="mt-1 wrapper container-user" v-show="!loading">
          <h5 class="my-3" :class="{'heaven': isHeaven}">{{ $t('views.seller.my_account.text_1568') }}</h5> <b-row>
            <b-col class="foto-user p-2" cols="12" md="6">
              <!-- User Id -->
              <div class="greenn_user_id_container">
                <span class="greenn_user_id">
                  <img style="margin-right: 10px;" src="greenn_user_id.png" alt="see_user" />
                  #{{ userId }}
                </span>
              </div>

              <!-- User Identity -->
              <b-form-group style="margin-top: 20px;" :label="$t('views.seller.my_account.text_1144')">
                <!-- Info Identity Needed -->
                <p v-if="!flowsAreValid">
                  {{ $t('seller.statements.modals.withdraw.text_845') }}
                </p>
                <p v-if="!flowBiometryExists && flowsAreValid" class="info-documento mb-2" data-anima="top">
                  {{ $t("views.seller.my_account.text_1145") }}

                </p>
                <p v-if="flowBiometryExists && !flowBiometryIsValid" class="info-documento mb-2" data-anima="top">
                  {{ $t("views.seller.my_account.text_1235_1") }}
                </p>
                <!-- Status Identity -->
                <div class="top-status">
                  <div class="aprovacao APPROVED btn-2 mt-2 mb-3" :class="{ heaven: isHeaven }"
                    v-if="flowBiometryIsValid">
                    <img v-if="!isHeaven" src="@/assets/img/icons/info-prod-aprovado.svg" />
                    <img v-else src="@/assets/img/icons-heaven/info-prod-aprovado.svg" />
                    {{ $t('views.seller.my_account.text_1146') }}
                  </div>

                  <!-- <div class="aprovacao btn-2 mt-2 mb-3" v-else-if="status_validation.status == 'REQUESTED'">
                    <img src="@/assets/img/icons/info-prod.svg" />
                    {{ $t('views.seller.my_account.text_1147') }}
                  </div>


                  <div class="aprovacao DISAPPROVED btn-2 mt-2 mb-3 d-none d-md-block"
                    v-else-if="status_validation.status == 'DISAPPROVED'">
                    <img src="@/assets/img/icons/info-prod-reprovado.svg" />
                    {{ $t('views.seller.my_account.text_1148') }}
                  </div> -->
                </div>
                <!-- Info Approved -->
                <p v-if="flowBiometryIsValid" class="info-documento">
                  {{ $t('views.seller.my_account.text_1149') }}<br />
                  {{ $t('views.seller.my_account.text_1150') }}
                  <router-link to="/extrato" class="link-aprovado">
                    {{ $t('views.seller.my_account.text_1151') }}
                  </router-link>
                </p>
                <!-- Info Requested -->
                <p class="info-documento" v-else-if="status_validation.status == 'REQUESTED'">
                  {{ $t('views.seller.my_account.text_1152') }} <br />
                  {{ $t('views.seller.my_account.text_1153') }}
                </p>
                <!-- Info Disapproved -->
                <p class="info-documento d-none d-md-block" v-else-if="status_validation.status == 'DISAPPROVED'">
                  {{ $t('views.seller.my_account.text_1154') }}<br />
                  {{ $t('views.seller.my_account.text_1155') }}
                  <b>{{ status_validation.observation }}</b>
                </p>
                <!-- Start/New Personal Identity Validation -->
                <BaseButton variant="info2" class="mt-3 mb-2 d-none d-md-block" @click="openValidation" v-if="
  !flowBiometryIsValid && flowsAreValid
                  ">
                  {{
                  $t("views.seller.my_account.text_1157")
                  }}
                </BaseButton>
              </b-form-group>
            </b-col>

            <b-col class="foto-user p-2" cols="12" md="6">
              <!-- Image -->
              <b-form-group :label="$t('views.seller.my_account.text_1227')">
                <vue-upload-multiple-image :multiple="false" @upload-success="uploadImageSuccess"
                  @before-remove="beforeRemove" @edit-image="editImage" :data-images="help_foto"
                  idUpload="userImgUpdate" editUpload="userImgEdit" dragText=""
                  :browseText="$t('views.seller.my_account.text_1158')" :showPrimary="false"
                  :showEdit="false"></vue-upload-multiple-image>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- Personal data -->
          <h5 class="my-3" :class="{'heaven': isHeaven}">{{ $t('views.seller.my_account.text_1159') }}</h5>
          <p class="info-documento my-3">{{ $t('views.seller.settings.text_1435') }}</p>
          <form data-vv-scope="user">
            <b-row v-if="isHeaven">
              <!-- Country -->
              <b-col class="p-2" cols="12" md="6">
                <label for="country">{{
                  $t("views.seller.my_account.text_1184_1")
                  }}</label>
                <LocalesTab name="country" :showCountryListGlobal="showCountryListGlobal"
                  :countryCodePessoal="countryCodePessoal" @show-country-list="showCountryList('tab')"
                  @update-country="changeCountryPessoal" />
              </b-col>
            </b-row>

            <b-row>
              <!-- Name -->
              <b-col class="p-2" cols="12" md="4">
                <b-form-group :label="$t('views.seller.my_account.text_1569')" label-for="name">
                  <b-form-input id="name" name="name" v-model.trim="user.name" type="text"
                    :disabled="fieldsDisabled.name" :placeholder="$t('views.seller.my_account.text_1160')"
                    v-validate="{ required: !loading, alpha_spaces: true }"
                    @blur="formatName('name')"
                    :class="{'heaven': isHeaven}"></b-form-input>
                  <b-form-invalid-feedback :state="!errors.has('user.name')">
                    {{ $t('views.seller.my_account.text_1161') }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <!-- Social Name -->
              <b-col class="p-2" cols="12" md="4">
                <b-form-group :label="$t('views.seller.my_account.social_name')" label-for="social_name">
                  <b-form-input id="social_name" name="social_name" v-model.trim="user.social_name" type="text"
                    :placeholder="$t('views.seller.my_account.insert_your_social_name')"
                    v-validate="{ required: !loading, alpha_spaces: true }"
                    @blur="formatName('social_name')"
                    :class="{'heaven': isHeaven}"></b-form-input>
                  <b-form-invalid-feedback :state="!errors.has('user.social_name')">
                    {{ $t('views.seller.my_account.insert_your_social_name') }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <!-- CellPhone -->
              <b-col class="p-2" cols="12" md="4">
                <b-form-group :label="$t('views.seller.my_account.text_1570')" label-for="phone">
                  <VuePhoneNumberInput v-model="whatsapp" :translations="$i18n.locale === 'pt' ? translations_pt : (
                      $i18n.locale === 'es' ? translations_es : translations_en
                    )" error-color="#ff0c37" size="lg"
                    :default-country-code="user.country_phone ? user.country_phone : isHeaven ? 'US' : 'BR'"
                    :preferred-countries="isHeaven ? ['US', 'BR', 'PT', 'FR', 'ES'] : ['BR', 'PT', 'US', 'FR', 'ES']"
                    ref="telinput" :required="true" :countries-height="50" :border-radius="5"
                    :class="{ heaven: isHeaven }" @update="onUpdatePessoal" />
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <!-- Email -->
              <b-col class="p-2" cols="12" md="6">
                <b-form-group :label="$t('views.seller.my_account.text_1573')" label-for="email">
                  <b-form-input id="email" name="email" v-model="user.email" type="text"
                    :placeholder="$t('views.seller.my_account.text_1574')" v-validate="{
                      required: !loading,
                      email: true,
                    }" :disabled="$store.getters.verifyEmail" :readonly="$store.getters.verifyEmail"
                    :class="{ heaven: isHeaven }"></b-form-input>
                  <b-form-invalid-feedback :state="!errors.has('user.email')">
                    {{ $t("views.seller.my_account.text_1162") }}
                  </b-form-invalid-feedback>
                  <p v-if="$store.getters.verifyEmail" class="info-documento mt-2">
                    {{ $t("views.seller.my_account.text_1163") }}
                  </p>
                </b-form-group>
              </b-col>

              <!-- CPF -->
              <b-col v-if="countryPessoal === 'BR'" class="p-2" cols="12" md="6">
                <b-form-group :label="$t('views.seller.my_account.text_1575')" label-for="cpf" id="campo-cpf">
                  <b-form-input id="cpf" name="cpf" v-model="user.cpf_cnpj" type="text"
                    :placeholder="$t('views.seller.my_account.text_1576')" v-validate="{
                      required: countryPessoal === 'BR' && !loading,
                      cpf_cnpj: true,
                    }" v-mask="
                      countryPessoal === 'BR'
                        ? ['###.###.###-##']
                        : ['XXXXXXXXXXXXXXXXXXXXXXXXXX']
                    " :disabled="
                      dados_user.cpf_cnpj
                        ? true
                        : false
                    " :readonly="
                      flowBiometryIsValid &&
                      dados_user.cpf_cnpj
                        ? true
                        : false
                    " :class="{ heaven: isHeaven }"></b-form-input>
                  <b-form-invalid-feedback :state="!errors.has('user.cpf')">
                    {{ $t("views.seller.my_account.text_1164") }}
                  </b-form-invalid-feedback>
                  <div v-if="showCpf_CnpjValid && !errors.has('user.cpf')">
                    <small class="name_invalid">
                      {{ $t('views.seller.my_account.text_1252') }}
                    </small>
                  </div>

                  <b-tooltip v-if="
                      flowBiometryIsValid && user.cpf_cnpj
                    " target="campo-cpf" :title="$t('views.seller.my_account.text_1165')" />
                </b-form-group>
              </b-col>
              <!-- RG -->
              <!-- <b-col
                v-if="countryPessoal === 'BR'"
                class="p-2"
                cols="12"
                md="6"
              >
                <b-form-group
                  :label="$t('views.seller.my_account.text_1577')"
                  label-for="rg"
                  id="campo-rg"
                >
                  <b-form-input
                    id="rg"
                    name="rg"
                    v-model="user.rg"
                    type="text"
                    :placeholder="$t('views.seller.my_account.text_1166')"
                    v-validate="{
                      required: countryPessoal === 'BR' && !loading,
                    }"
                    v-mask="['#############']"
                    :disabled="
                      flowBiometryIsValid && dados_user.rg
                        ? true
                        : false
                    "
                    :readonly="
                      flowBiometryIsValid && dados_user.rg
                        ? true
                        : false
                    "
                    :class="{ heaven: isHeaven }"
                  ></b-form-input>
                  <b-form-invalid-feedback :state="!errors.has('user.rg')">
                    {{ $t("views.seller.my_account.text_1167") }}
                  </b-form-invalid-feedback>

                  <b-tooltip
                    v-if="flowBiometryIsValid && user.rg"
                    target="campo-rg"
                    :title="$t('views.seller.my_account.text_1165')"
                  />
                </b-form-group>
              </b-col> -->
              <!-- Document Type -->
              <b-col v-if="countryPessoal !== 'BR'" class="p-2" cols="12" md="6" id="campo-document_type">
                <label for="document_type">{{
                  $t("views.seller.register.text_1319_1")
                  }}</label>
                <input id="document_type" type="text" v-model="user.document_type" name="document_type" :disabled="
                    dados_user.document_type
                      ? true
                      : false
                  " :readonly="
                    flowBiometryIsValid &&
                    dados_user.cpf_cnpj
                      ? true
                      : false
                  " class="w-100 form-control form-group" :class="{ heaven: isHeaven }"
                  v-validate="{ required: countryPessoal !== 'BR' && !loading }" />
                <b-form-invalid-feedback :state="submit ? !errors.has('document_type') : null">
                  {{ $t("views.seller.register.text_1319_3") }}
                </b-form-invalid-feedback>
                <b-tooltip v-if="
                    flowBiometryIsValid && user.document_type
                  " target="campo-document_type" :title="$t('views.seller.my_account.text_1165')" />
              </b-col>
              <!-- Document Number -->
              <b-col v-if="countryPessoal !== 'BR'" class="p-2" cols="12" md="6" id="campo-document_number">
                <label for="document_number">{{
                  $t("views.seller.register.text_1320_1")
                  }}</label>
                <input id="document_number" type="text" v-model="user.cpf_cnpj" name="document_number"
                  :placeholder="$t('views.seller.register.text_1320_2')" :disabled="
                    dados_user.cpf_cnpj
                      ? true
                      : false
                  " :readonly="
                    flowBiometryIsValid &&
                    dados_user.cpf_cnpj
                      ? true
                      : false
                  " class="w-100 form-control form-group" :class="{ heaven: isHeaven }"
                  v-validate="{ required: countryPessoal !== 'BR' && !loading }" />
                <b-form-invalid-feedback :state="submit ? !errors.has('document_number') : null">
                  {{ $t("views.seller.register.text_1320_3") }}
                </b-form-invalid-feedback>
                <b-tooltip v-if="flowBiometryIsValid && user.cpf_cnpj" target="campo-document_number"
                  :title="$t('views.seller.my_account.text_1165')" />
              </b-col>
              <!-- Mother Name -->
              <b-col class="p-2" cols="12" md="6">
                <b-form-group :label="$t('views.seller.my_account.text_1569_1')" label-for="mother_name">
                  <b-form-input id="mother_name" name="mother_name" v-model="user.mother_name"
                    :disabled="fieldsDisabled.mother_name" type="text"
                    :placeholder="$t('views.seller.my_account.text_1160')"
                    v-validate="{ required: !loading, alpha_spaces: true }"
                    @blur="formatName('mother_name')"
                    :class="{'heaven': isHeaven}"></b-form-input>
                  <b-form-invalid-feedback :state="!errors.has('user.mother_name')">
                    {{ $t('views.seller.my_account.text_1161_1') }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <!-- Birth Date -->
              <b-col class="p-2" cols="12" md="6">
                <b-form-group :label="$t('seller.address.listagem_enderecos.birth_date')" label-for="birth_date">
                  <b-form-input id="birth_date" name="birth_date" v-model="user.birth_date" type="date"
                    :disabled="fieldsDisabled.birth_date" v-validate="{
                      required: true,
                      date_format: 'yyyy-MM-dd',
                      date_between: ['1900-01-01',moment().format('YYYY-MM-DD')]
                    }" :class="{ heaven: isHeaven }" placeholder="##/##/####"></b-form-input>
                  <b-form-invalid-feedback
                    :state="errors.items.length && errors.items.some(x => x.field === 'birth_date') ? false : true">
                    {{ $t('seller.address.listagem_enderecos.fill_birth_date') }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <!-- Is Politically Exposed Person -->
              <b-col class="warning p-2 mt-3 mb-0" cols="6" md="2">
                <img v-show="user.is_politically_exposed_person" id="warning" src="@/assets/img/icons/warning.svg" />
                <b-tooltip target="warning">
                  <template>
                    {{ $t('views.seller.my_account.text_1257') }}:
                    <a target="_blank"
                      href="https://www.gov.br/coaf/pt-br/assuntos/informacoes-as-pessoas-obrigadas/o-que-sao-pessoas-expostas-politicamente-peps">{{
                      $t('views.seller.settings.text_1438') }}</a>
                  </template>
                </b-tooltip>
                <span class="have-account">
                  {{ $t('views.seller.my_account.text_1256') }}
                </span>
              </b-col>
              <b-col v-if="switchIsLoaded" class="p-2"
                :class="{'switchFalse' : !user.is_politically_exposed_person, 'switchTrue' : user.is_politically_exposed_person}"
                cols="2" sm="2">
                <b-form-group class="mt-3 mb-0">
                  <switch-button :isChecked="isChecked" :newValueReturn="newValueReturnSwitch"
                    :disabled="fieldsDisabled.is_politically_exposed_person" />
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Address data -->
            <h5 class="my-3">{{ $t('views.seller.my_account.text_1168') }}</h5>
            <b-row>
              <!-- Zipcode -->
              <b-col class="p-2" cols="12" md="3">
                <b-form-group :label="$t('views.seller.my_account.text_1578')+' *'" label-for="zip_code">
                  <b-form-input id="zip_code" name="zip_code" v-model="address_user.zip_code"
                    :disabled="fieldsDisabled.address" :placeholder="$t('views.seller.my_account.text_1169')"
                    v-mask="['#####-###']" type="text" v-validate="'required'" :class="{ 'heaven': isHeaven }"
                    @keyup="preencherCep('usuario')"></b-form-input>
                  <b-form-invalid-feedback :state="!errors.has('user.zip_code')">
                    {{ $t('seller.address.listagem_enderecos.fill_postal_code') }}
                  </b-form-invalid-feedback>
                  <div v-if="showZipCodeValid && !errors.has('user.zip_code')">
                    <small class="name_invalid">
                      {{ $t('seller.address.listagem_enderecos.fill_postal_code_1') }}
                    </small>
                  </div>
                </b-form-group>
              </b-col>
              <!-- Street -->
              <b-col class="p-2" cols="12" md="9">
                <b-form-group :label="$t('views.seller.my_account.text_1170')" label-for="rua">
                  <b-form-input id="street" name="street" v-model="address_user.street"
                    :disabled="fieldsDisabled.address" type="text"
                    :placeholder="$t('views.seller.my_account.text_1170')" v-validate="'required'"
                    :class="{ 'heaven': isHeaven }"></b-form-input>
                  <b-form-invalid-feedback :state="!errors.has('user.street')">
                    {{ $t('seller.address.listagem_enderecos.fill_public_place') }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <!-- Number -->
              <b-col class="p-2" cols="12" md="4">
                <b-form-group :label="$t('views.seller.my_account.text_1579')" label-for="num">
                  <b-form-input id="num" name="number" v-model="address_user.number" :disabled="fieldsDisabled.address"
                    type="text" v-mask="['########']" :placeholder="$t('views.seller.my_account.text_1171')"
                    v-validate="{ required: !loading }" :class="{'heaven': isHeaven}"></b-form-input>
                  <b-form-invalid-feedback :state="!errors.has('user.number')">
                    {{ $t('seller.address.listagem_enderecos.fill_number') }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <!-- Neighborhood -->
              <b-col class="p-2" cols="12" md="8">
                <b-form-group :label="$t('views.seller.my_account.text_1580')" label-for="bairro">
                  <b-form-input id="neighborhood" name="neighborhood" v-model="address_user.neighborhood"
                    :disabled="fieldsDisabled.address" type="text"
                    :placeholder="$t('views.seller.my_account.text_1172')" v-validate="'required'"
                    :class="{ 'heaven': isHeaven }"></b-form-input>
                  <b-form-invalid-feedback :state="!errors.has('user.neighborhood')">
                    {{ $t('seller.address.listagem_enderecos.fill_neighborhood') }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <!-- Cidade -->
              <b-col class="p-2" cols="12" md="6">
                <b-form-group :label="$t('views.seller.my_account.text_1581')" label-for="cidade">
                  <b-form-input id="city" name="city" v-model="address_user.city" :disabled="fieldsDisabled.address"
                    type="text" :placeholder="$t('views.seller.my_account.text_1582')" v-validate="'required'"
                    :class="{ 'heaven': isHeaven }"></b-form-input>
                  <b-form-invalid-feedback :state="!errors.has('user.city')">
                    {{ $t('seller.address.listagem_enderecos.fill_city') }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <!-- State -->
              <b-col class="p-2" cols="12" md="6">
                <b-form-group :label="$t('views.seller.my_account.text_1583')" label-for="estado">
                  <b-form-select id="state" name="state" v-model="address_user.state" :disabled="fieldsDisabled.address"
                    :options="brazilStates" :placeholder="$t('views.seller.my_account.text_1584')"
                    v-validate="'required'" :class="{ 'heaven': isHeaven }"></b-form-select>
                  <b-form-invalid-feedback :state="!errors.has('user.state')">
                    {{ $t('seller.address.listagem_enderecos.fill_state') }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>

            <!-- Complement -->
            <!-- <b-row class="px-2">
              <b-col>
                <b-form-group :label="$t('seller.address.listagem_enderecos.complement')" label-for="complement">
                  <b-form-input
                    id="complement"
                    v-model="address_user.complement"
                    :placeholder="$t('seller.address.listagem_enderecos.complement_of_address')"
                    type="text"
                    name="complement"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row> -->

            <!-- Custom Email -->
            <h5 class="my-3" :class="{'heaven': isHeaven}">{{ $t('views.seller.my_account.text_1229') }}</h5>
            <b-col class="p-2" cols="12" md="6">
              <b-form-group>
                <b-form-input id="customEmail" name="customEmail" v-model="user.customEmail" type="text"
                  :placeholder="$t('views.seller.my_account.text_1231')" :class="{ heaven: isHeaven }" v-validate="{
                    email: true,
                  }"></b-form-input>
                <b-form-invalid-feedback :state="!errors.has('user.customEmail')">
                  {{ $t("views.seller.my_account.text_1162") }}
                </b-form-invalid-feedback>
                <p class="info-documento mt-2">
                  {{ $t("views.seller.my_account.text_1230") }}
                </p>
              </b-form-group>
            </b-col>
            <!-- Custom Email -->

            <h5 class="my-3" :class="{ heaven: isHeaven }">
              {{ $t("views.seller.my_account.text_1585") }}
            </h5>
            <div>
              <BaseButton :class="{ heaven: isHeaven }" variant="black" @click="openResetSenha">
                {{ $t("views.seller.my_account.text_1173") }}
              </BaseButton>
            </div>
            <div class="mt-3" v-if="isWebView">
              <BaseButton variant="danger" @click="deleteAccount">
                {{ $t("views.seller.my_account.text_1253") }}
              </BaseButton>
            </div>
            <!-- Save Edit -->
            <b-row>
              <b-col cols="12" class="d-flex justify-content-end mt-3">
                <BaseButton :class="{ heaven: isHeaven }" variant="primary" @click="onSubmit('user')">
                  {{ $t("views.seller.my_account.text_1174") }}
                </BaseButton>
              </b-col>
            </b-row>
          </form>
        </b-container>
      </b-tab>
      <!-- Enterprise -->
      <b-tab :title="`${$t('views.seller.my_account.text_1175')} ${hasBothCompanyDocumentsApproved ? '✔️' : ''}`">
        <b-container v-if="!loading" fluid class="mt-1 wrapper container-user">

          <div v-if="company  && company.disabled == 1" class="card-validation">
            <p>
              {{ $t("views.seller.my_account.text_1258") }}
            </p>
          </div>

          <h5 class="my-3" :class="{'heaven': isHeaven}">
            {{ $t('views.seller.my_account.text_1176') }}
            <p class="info-documento">{{ $t('views.seller.my_account.text_1250') }}</p>
            <p class="info-documento my-3">{{ $t('views.seller.settings.text_1436') }}</p>
          </h5>
          <form data-vv-scope="company">
            <b-row v-if="isHeaven">
              <!-- Country -->
              <b-col class="p-2" cols="12" md="6">
                <label for="country">{{
                  $t("views.seller.my_account.text_1184_1")
                  }}</label>
                <LocalesTab :showCountryListGlobal="showCountryListGlobal" :countryCodeEmpresa="countryCodeEmpresa"
                  @update-country="changeCountryEmpresa" @show-country-list="showCountryList('tab')"
                  @changeCountry="changeCountry" />
              </b-col>
            </b-row>

            <b-row>
              <!-- Social Reazon -->
              <b-col class="p-2" cols="12" md="6">
                <b-form-group :label="$t('views.seller.my_account.text_1586')+' *'" label-for="name_company">
                  <b-form-input id="name_company" name="name_company" v-model="company.name"
                    :disabled="fieldsDisabled.company_name" type="text"
                    :placeholder="$t('views.seller.my_account.text_1177')" :class="{ heaven: isHeaven }"
                    v-validate="'required'"></b-form-input>
                  <b-form-invalid-feedback :state="!errors.has('company.name_company')">
                    {{ $t("views.seller.my_account.text_1178") }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <!-- Fantasy Name -->
              <b-col class="p-2" cols="12" md="6">
                <b-form-group :label="$t('views.seller.my_account.text_1179')" label-for="fantasy_name">
                  <b-form-input id="fantasy_name" name="fantasy_name" v-model="company.fantasy_name"
                    :disabled="fieldsDisabled.fantasy_name" type="text"
                    :placeholder="$t('views.seller.my_account.text_1180')" :class="{'heaven': isHeaven}"></b-form-input>
                  <b-form-invalid-feedback :state="!errors.has('company.fantasy_name')">
                    {{ $t('views.seller.my_account.text_1178') }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <!-- CNPJ -->
              <b-col v-if="countryEmpresa === 'BR'" class="p-2" cols="12" md="6">
                <b-form-group :label="$t('views.seller.my_account.text_1181') +' *'" label-for="cnpj" id="campo-cnpj">
                  <b-form-input id="cnpj" name="cnpj" v-model="company.cnpj"
                    :placeholder="$t('views.seller.my_account.text_1182')" v-mask="['##.###.###/####-##']"
                    v-validate="{required: countryEmpresa === 'BR' && !loading}"
                    :disabled="hasSocialContractApproved || fieldsDisabled.cnpj" :readonly="hasSocialContractApproved"
                    :class="{'heaven': isHeaven}" @input="updateCnpjIsValid"></b-form-input>
                  <b-form-invalid-feedback
                    :state="(errors.items.length && errors.items.some(x => x.field === 'cnpj')) || !cpnjIsValid ? false : true">
                    {{
                    !errors.items.some(x => x.field === 'cnpj') && !cpnjIsValid
                    ? $t('views.seller.my_account.text_1587_1')
                    : $t('views.seller.my_account.text_1587')
                    }}
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-tooltip v-if="hasSocialContractApproved" target="campo-cnpj"
                  :title="$t('views.seller.my_account.text_1183')" />
              </b-col>
              <!-- Document Type -->
              <b-col v-if="countryEmpresa !== 'BR'" class="p-2" cols="12" md="6">
                <label for="document_type">{{
                  $t("views.seller.register.text_1319_1")
                  }}</label>
                <input id="document_type" type="text" v-model="company.document_type" name="document_type"
                  :disabled="hasSocialContractApproved" :readonly="hasSocialContractApproved"
                  class="w-100 form-control form-group" :class="{ heaven: isHeaven }"
                  v-validate="{ required: countryEmpresa !== 'BR' && !loading }" />
                <b-form-invalid-feedback :state="submit ? !errors.has('document_type') : null">
                  {{ $t("views.seller.register.text_1319_3") }}
                </b-form-invalid-feedback>
              </b-col>
              <!-- Document Number -->
              <b-col v-if="countryEmpresa !== 'BR'" class="p-2" cols="12" md="6">
                <label for="document_number">{{
                  $t("views.seller.register.text_1320_1")
                  }}</label>
                <input id="document_number" type="text" v-model="company.cnpj" name="document_number"
                  :placeholder="$t('views.seller.register.text_1320_2')" :disabled="hasSocialContractApproved"
                  :readonly="hasSocialContractApproved" class="w-100 form-control form-group"
                  :class="{ heaven: isHeaven }" v-validate="{ required: countryEmpresa !== 'BR' && !loading }" />
                <b-form-invalid-feedback :state="submit ? !errors.has('document_number') : null">
                  {{ $t("views.seller.register.text_1320_3") }}
                </b-form-invalid-feedback>
              </b-col>

              <!-- Telephone -->
              <b-col class="p-2" cols="12" md="6">
                <b-form-group :label="$t('views.seller.my_account.text_1570')" label-for="phone_company">
                  <VuePhoneNumberInput v-model="whatsappEmpresa" :translations="
                      $i18n.locale === 'pt'
                        ? translations_pt
                        : $i18n.locale === 'es'
                        ? translations_es
                        : translations_en
                    " error-color="#ff0c37" size="lg" :default-country-code="
                      company.country_phone
                        ? company.country_phone
                        : isHeaven
                        ? 'US'
                        : 'BR'
                    " :preferred-countries="
                      isHeaven
                        ? ['US', 'BR', 'PT', 'FR', 'ES']
                        : ['BR', 'PT', 'US', 'FR', 'ES']
                    " ref="telinput" :countries-height="50" :border-radius="5" :require="false"
                    :class="{ heaven: isHeaven }" @update="onUpdateEmpresa" />
                  <b-form-invalid-feedback style="margin-top: 15px;" :state="submit ? results_tel.isValid : null">
                    {{ $t("views.seller.my_account.text_1251") }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <!-- Social Contract -->
              <b-col class="p-2" cols="12" md="6">
                <!-- Input File -->
                <b-form-group v-if="!hasSocialContract" :label="$t('views.seller.my_account.text_1184') +' *'"
                  label-for="contract" data-anima="top">
                  <b-form-file id="contract" name="contract" plain accept=".pdf" v-model="contract_user"
                    v-validate="{required: !hasSocialContract && !loading}" :class="{'heaven': isHeaven}"
                    @change="changeContract = true"></b-form-file>

                  <b-form-invalid-feedback :state="!errors.has('company.contract')">
                    {{ $t('views.seller.my_account.text_1185') }}
                  </b-form-invalid-feedback>
                  <p class="info-documento">
                    {{ $t('views.seller.my_account.text_1186') }}
                  </p>
                </b-form-group>

                <FeedbackCompanyDocuments v-show="hasSocialContract" :company="company" type="social_contract"
                  @remove-social-contract="removeSocialContractToEdit"
                  @open-andamento-docs="(n) => openAndamentoDocs(n)" />

              </b-col>
            </b-row>


            <b-row> </b-row>
            <!-- Support Data -->
            <h5 class="my-3" :class="{ heaven: isHeaven }">
              {{ $t("views.seller.my_account.text_1592") }}
            </h5>
            <b-row>
              <!-- E-mail -->
              <b-col class="p-2" cols="12" md="6">
                <b-form-group :label="$t('views.seller.my_account.text_1573')" label-for="email">
                  <b-form-input id="email" name="email" v-model="company.email" type="text"
                    :placeholder="$t('views.seller.my_account.text_1593')" :class="{ heaven: isHeaven }"></b-form-input>
                  <b-form-invalid-feedback :state="!errors.has('company.email')">
                    {{ $t("views.seller.my_account.text_1162") }}
                  </b-form-invalid-feedback>
                  <p v-if="company.email" class="info-documento mt-2">
                    {{ $t("views.seller.my_account.text_1195") }}
                  </p>
                </b-form-group>
              </b-col>
              <!-- Telephone -->
              <b-col v-if="!loadingPhone" class="p-2" cols="12" md="6">
                <b-form-group :label="$t('views.seller.my_account.text_1594')" label-for="support_telephone">
                  <VuePhoneNumberInput v-model="whatsappEmpresaSupport" :translations="
                      $i18n.locale === 'pt'
                        ? translations_pt
                        : $i18n.locale === 'es'
                        ? translations_es
                        : translations_en
                    " error-color="#ff0c37" size="lg" :default-country-code="
                      company.support_country_phone
                        ? company.support_country_phone
                        : isHeaven
                        ? 'US'
                        : 'BR'
                    " :preferred-countries="['BR', 'PT', 'US', 'FR', 'ES']" ref="telinput" :required="false"
                    :countries-height="50" :border-radius="5" :class="{ heaven: isHeaven }"
                    @update="onUpdateEmpresaSupport" />
                </b-form-group>
              </b-col>
            </b-row>

            <b-row> </b-row>
            <!-- Address Data -->
            <h5 class="my-3" :class="{ heaven: isHeaven }">
              {{ $t("views.seller.my_account.text_1597") }}
            </h5>
            <!-- Zipcode -->
            <b-row>
              <b-col class="p-2" cols="12" md="6">
                <b-form-group :label="$t('views.seller.my_account.text_1598')" label-for="cep_company">
                  <b-form-input id="cep_company" v-model="company.zip_code"
                    :placeholder="$t('views.seller.my_account.text_1599')" v-mask="['#####-###']" type="text"
                    :class="{ heaven: isHeaven }" @keyup="preencherCep('empresa')"></b-form-input>
                  <div v-if="showZipCodeCompanieValid">
                    <small class="name_invalid">
                      {{ $t('seller.address.listagem_enderecos.fill_postal_code_1') }}
                    </small>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <!-- Street -->
              <b-col class="p-2" cols="12" md="6">
                <b-form-group :label="$t('views.seller.my_account.text_1170')" label-for="street">
                  <b-form-input id="rua_company" v-model="company.street" type="text"
                    :placeholder="$t('views.seller.my_account.text_1600')" :class="{ heaven: isHeaven }"></b-form-input>
                </b-form-group>
              </b-col>
              <!-- Number -->
              <b-col class="p-2" cols="12" md="6">
                <b-form-group :label="$t('views.seller.my_account.text_1579')" label-for="number">
                  <b-form-input v-model="company.number" :placeholder="$t('views.seller.my_account.text_1601')"
                    :class="{ heaven: isHeaven }"></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

            <!-- Neighborhood -->
            <b-row>
              <b-col class="p-2" cols="12" md="6">
                <b-form-group :label="$t('views.seller.my_account.text_1580')" label-for="neighborhood">
                  <b-form-input id="bairro_company" v-model="company.neighborhood" type="text"
                    :placeholder="$t('views.seller.my_account.text_1602')" :class="{ heaven: isHeaven }"></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

            <!-- City -->
            <b-row>
              <b-col class="p-2" cols="12" md="6">
                <b-form-group :label="$t('views.seller.my_account.text_1581')" label-for="cidade_company">
                  <b-form-input id="cidade_company" v-model="company.city" type="text"
                    :placeholder="$t('views.seller.my_account.text_1603')" :class="{ heaven: isHeaven }"></b-form-input>
                </b-form-group>
              </b-col>
              <!-- State -->
              <b-col class="p-2" cols="12" md="6">
                <b-form-group :label="$t('views.seller.my_account.text_1583')" label-for="estado_company">
                  <b-form-input id="estado_company" v-model="company.state" type="text"
                    :placeholder="$t('views.seller.my_account.text_1604')" :class="{ heaven: isHeaven }"></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Save Button -->
            <b-row>
              <b-col cols="12" class="p-2 d-flex justify-content-end mt-3">
                <BaseButton variant="primary" @click="onSubmit('company')">
                  {{ $t("views.seller.my_account.text_1174") }}
                </BaseButton>
              </b-col>
            </b-row>
          </form>
        </b-container>
      </b-tab>
      <!-- Bank Accounts Redirect -->
      <b-tab :title="$t('views.seller.my_account.text_1605')" @click="acessarContaCorrente">
      </b-tab>
      <!-- My Fees -->
      <b-tab :title="$t('views.seller.my_account.text_1607')">
        <MyFees v-if="!loading"></MyFees>
      </b-tab>
      <!-- Loading -->
      <div v-if="loading" key="loading" class="py-5 my-5 d-flex justify-content-center align-items-center">
        <b-spinner variant="dark" label="Spinning"> </b-spinner>
        <p class="ml-4 info-documento">
          {{ $t("views.seller.my_account.text_1596") }}
        </p>
      </div>
    </b-tabs>
    <ResetPassword :user_id="user.id" />
    <AndamentoDocs :docs="andamento" />
    <ModalValidation @close="initDados" />
    <CallEvaluation />
    <UserValidation />
  </div>
</template>

<script>
import UserValidation from "@/components/Seller/MyAccount/modals/UserValidation.vue";
import VuePhoneNumberInput from 'vue-phone-number-input'
import VueUploadMultipleImage from 'vue-upload-multiple-image'
import ResetPassword from '@/components/Seller/MyAccount/modals/reset_password.vue'
import AndamentoDocs from '@/components/Seller/MyAccount/modals/AndamentoDocs.vue'
import FeedbackCompanyDocuments from '@/components/Seller/MyAccount/FeedbackCompanyDocuments.vue'
import Locales from '@/components/Locales'
import LocalesTab from '@/components/LocalesTab'
import MyFees from '@/components/Seller/MyAccount/MyFees.vue'
import moment from "moment";
import validateCNPJ from '@/utils/validateCNPJ.js'
import UserService from "@/services/resources/UserService";
import ValidationService from "@/services/resources/ValidationService";
import UserSettingsService from "@/services/resources/UserSettingsService";
import SwitchButton from '@/components/SwitchButton.vue'
import IdWall from "@/mixins/IdWall";

import { mapGetters } from "vuex";

const serviceValidation = ValidationService.build();
const serviceUser = UserService.build();
const serviceUserSettings = UserSettingsService.build();

export default {
  name: "MyAccount",
  components: {
    VuePhoneNumberInput,
    VueUploadMultipleImage,
    ResetPassword,
    AndamentoDocs,
    FeedbackCompanyDocuments,
    Locales,
    LocalesTab,
    MyFees,
    UserValidation,
    SwitchButton,
  },

  mixins: [IdWall],

  data() {
    return {
      showZipCodeCompanieValid:false,
      showZipCodeValid: false,
      showCpf_CnpjValid:false,
      showMotherNameValid:false,
      showNameValid: false,
      results_tel: {
        isValid: true,
      },
      moment: moment,
      address_user: {
        zip_code: "00000-000",
        street: "",
        number: "",
        neighborhood: "",
        city: "",
        state: "",
        complement: ""
      },
      andamento: [],
      changeContract: false,
      changeContractFinancial: false,
      company: {
        document_type: '',
        documents: [{ status: 'REQUESTED' }],
        name: '',
        fantasy_name: '',
        telephone: '',
        country_phone: '',
        support_telephone: '',
        support_country_phone: '',
        email: '',
        cnpj: '',
        zip_code: '00000-000',
        street: '',
        number: '',
        neighborhood: '',
        city: '',
        state: '',
        country: 'BR',
      },
      contract_user: null,
      contract_financial_user: null,
      financial_document_type: null,
      social_contract_options: [
        { value: null, text: this.$t('seller.products.new.text_626'), disabled: true },
        { value: "FATURAMENTO", text: this.$t('views.seller.my_account.text_1235') },
        { value: "BALANCO", text: this.$t('views.seller.my_account.text_1236') }
      ],
      country: 'BR',
      countryEmpresa: 'BR',
      countryPessoal: 'BR',
      document_user: [],
      dados_user: {
        address: {}
      },
      foto_user: [],
      help_foto: [],
      loading: false,
      loadingPhone: false,
      navigator: navigator,
      submit: false,
      showCountryListTab: false,
      showCountryListGlobal: false,
      status_validation: {
        status: "",
        observation: "",
      },
      translations_pt: {
        countrySelectorLabel: "Código do país",
        countrySelectorError: "Escolha um país",
        phoneNumberLabel: "Número de Telefone",
        example: "Exemplo: ",
      },
      translations_en: {
        countrySelectorLabel: "Country code",
        countrySelectorError: "Choose a country",
        phoneNumberLabel: "Phone number",
        example: "Example: ",
      },
      translations_es: {
        countrySelectorLabel: "Código de país",
        countrySelectorError: "Elige un país",
        phoneNumberLabel: "Número de teléfono",
        example: "Ejemplo: ",
      },
      troca_senha: false,
      user: {
        id: -1,
        name: "",
        cellphone: "",
        country_phone: "",
        email: "",
        birth_date: "",
        cpf_cnpj: "",
        document_type: "",
        rg: "",
        image: [],
        documents: [{ status: "REQUESTED" }],
        old_password: "",
        new_password: "",
        customEmail: "",
        is_politically_exposed_person: false,
      },
      whatsapp: "",
      whatsappEmpresa: "",
      whatsappEmpresaSupport: "",
      countryCodePessoal: null,
      countryCodeEmpresa: null,
      cpnjIsValid: true,
      isChecked: false,
      switchIsLoaded: false,
      brazilStates: [
        { value: null, text: 'Selecione o estado' },
        { value: 'AC', text: 'AC' },
        { value: 'AL', text: 'AL' },
        { value: 'AP', text: 'AP' },
        { value: 'AM', text: 'AM' },
        { value: 'BA', text: 'BA' },
        { value: 'CE', text: 'CE' },
        { value: 'DF', text: 'DF' },
        { value: 'ES', text: 'ES' },
        { value: 'GO', text: 'GO' },
        { value: 'MA', text: 'MA' },
        { value: 'MT', text: 'MT' },
        { value: 'MS', text: 'MS' },
        { value: 'MG', text: 'MG' },
        { value: 'PA', text: 'PA' },
        { value: 'PB', text: 'PB' },
        { value: 'PR', text: 'PR' },
        { value: 'PE', text: 'PE' },
        { value: 'PI', text: 'PI' },
        { value: 'RJ', text: 'RJ' },
        { value: 'RN', text: 'RN' },
        { value: 'RS', text: 'RS' },
        { value: 'RO', text: 'RO' },
        { value: 'RR', text: 'RR' },
        { value: 'SC', text: 'SC' },
        { value: 'SP', text: 'SP' },
        { value: 'SE', text: 'SE' },
        { value: 'TO', text: 'TO' },
      ],
      flowsAreValid: false,
    }
  },
  watch:{
    'company.zip_code'(e){
      if(e && e.length >= 9){
        this.showZipCodeCompanieValid = false;
        return
      }
      this.showZipCodeCompanieValid = true;
    },
    'address_user.zip_code'(e){
      if(e && e.length >= 9){
        this.showZipCodeValid = false;
        return
      }
      this.showZipCodeValid = true;
    },
    'user.name'(){
      const allValidatedName = this.nameValidationSteps(this.user.name);
      const isAllValidatedName = allValidatedName.every((step) => step.validated === true);
      if (!isAllValidatedName) {
        this.showNameValid = true;
        return;
      }
      this.showNameValid = false;
    },
    'user.mother_name'(){
      const allValidatedMotherName = this.nameValidationSteps(this.user.mother_name);
      const isAllValidatedMotherName = allValidatedMotherName.every((step) => step.validated === true);
      if (!isAllValidatedMotherName) {
        this.showMotherNameValid = true;
        return;
      }
      this.showMotherNameValid = false;
    },
    'user.cpf_cnpj'(){
      if(this.user?.cpf_cnpj?.length === 14 || this.user?.cpf_cnpj?.length === 18){
        this.showCpf_CnpjValid = false;
        return
      }
      this.showCpf_CnpjValid = true;
    },
  },
  computed: {
    fieldsDisabled() {
      const enriched_data = this.user.enriched_data == '1' ? true : false;
      let dados_user = this.dados_user || {};
      let is_politically_exposed_person = dados_user.is_politically_exposed_person !== undefined ? true : false;
      let birth_date = dados_user.birth_date ? true : false;
      let name = (enriched_data && dados_user.name) || !!dados_user.name ? true : false;
      let mother_name = dados_user.mother_name ? true : false;
      let address = (
        enriched_data &&
        !!dados_user.address
      ) || !!dados_user.address

      let fantasy_name = dados_user.company && ( dados_user.company.fantasy_name || dados_user.company.disabled) ? true : false;
      let company_name = dados_user.company && (dados_user.company.name || dados_user.company.disabled) ? true : false;
      let cnpj = dados_user.company && (dados_user.company.cnpj || dados_user.company.disabled) ? true : false;
      return {
        is_politically_exposed_person,
        birth_date,
        name,
        mother_name,
        address,
        fantasy_name,
        company_name,
        cnpj
      }
    },
    ...mapGetters(["getFlags"]),
    userLogged() {
      return this.$store.getters.getProfile;
    },
    userId() {
      return this.$store.getters.getProfile.id;
    },
    isHeaven() {
      return this.$store.getters["getIsHeaven"];
    },
    hasSocialContract() {
      return this.company?.documents?.length && this.company.documents.some(x => x.document_type === 'CONTRATO_SOCIAL' || x.document_type === null)
    },
    hasFinancialDocument() {
      return this.company?.documents?.length && this.company.documents.some(x => x.document_type === 'FATURAMENTO' || x.document_type === 'BALANCO')
    },
    hasUserDocumentsApproved() {
      return this.flowBiometryIsValid
    },
    hasBothCompanyDocumentsApproved() {
      if (
        this.company?.documents?.length &&
        this.company.documents.some(x => x.document_type === 'CONTRATO_SOCIAL' || x.document_type === null)
        // this.company.documents.some(x => x.document_type === 'FATURAMENTO' || x.document_type === 'BALANCO')
      ) {
        return (
          this.company.documents.some(x => (x.document_type === 'CONTRATO_SOCIAL' || x.document_type === null) && x.status === 'APPROVED')
          // this.company.documents.some(x => (x.document_type === 'FATURAMENTO' || x.document_type === 'BALANCO') && x.status === 'APPROVED')
        )
      } else {
        return false
      }
    },
    hasSocialContractApproved() {
      if (
        this.company?.documents?.length &&
        this.company.documents.some(x => x.document_type === 'CONTRATO_SOCIAL' || x.document_type === null)
      ) {
        return this.company.documents.some(x => (x.document_type === 'CONTRATO_SOCIAL' || x.document_type === null) && x.status === 'APPROVED')
      } else {
        return false
      }
    },
    hasSocialContractRequested() {
      if (
        this.company?.documents?.length &&
        this.company.documents.some(x => x.document_type === 'CONTRATO_SOCIAL' || x.document_type === null)
      ) {
        return this.company.documents.some(x => (x.document_type === 'CONTRATO_SOCIAL' || x.document_type === null) && x.status === 'REQUESTED')
      } else {
        return false
      }
    },
    hasSocialContractDisapproved() {
      if (
        this.company?.documents?.length &&
        this.company.documents.some(x => x.document_type === 'CONTRATO_SOCIAL' || x.document_type === null)
      ) {
        return this.company.documents.some(x => (x.document_type === 'CONTRATO_SOCIAL' || x.document_type === null) && x.status === 'DISAPPROVED')
      } else {
        return false
      }
    },
    getSocialContractDisapproveds() {
      if (
        this.company?.documents?.length &&
        this.company.documents.some(x => x.document_type === 'CONTRATO_SOCIAL' || x.document_type === null)
      ) {
        if(this.company.documents.some(x => (x.document_type === 'CONTRATO_SOCIAL' || x.document_type === null) && x.status === 'DISAPPROVED')) {
          const disapproved_documents = this.company.documents.filter(x => (x.document_type === 'CONTRATO_SOCIAL' || x.document_type === null) && x.status === 'DISAPPROVED')
          return disapproved_documents
        } else return []
      } else return []
    },
    hasFinancialDocumentApproved() {
      if (
        this.company?.documents?.length &&
        this.company.documents.some(x => x.document_type === 'FATURAMENTO' || x.document_type === 'BALANCO')
      ) {
        return this.company.documents.some(x => (x.document_type === 'FATURAMENTO' || x.document_type === 'BALANCO') && x.status === 'APPROVED')
      } else {
        return false
      }
    },
    hasFinancialDocumentRequested() {
      if (
        this.company?.documents?.length &&
        this.company.documents.some(x => x.document_type === 'FATURAMENTO' || x.document_type === 'BALANCO')
      ) {
        return this.company.documents.some(x => (x.document_type === 'FATURAMENTO' || x.document_type === 'BALANCO') && x.status === 'REQUESTED')
      } else {
        return false
      }
    },
    hasFinancialDocumentDisapproved() {
      if (
        this.company?.documents?.length &&
        this.company.documents.some(x => x.document_type === 'FATURAMENTO' || x.document_type === 'BALANCO')
      ) {
        return this.company.documents.some(x => (x.document_type === 'FATURAMENTO' || x.document_type === 'BALANCO') && x.status === 'DISAPPROVED')
      } else {
        return false
      }
    },
    getFinancialDocumentDisapproveds() {
      if (
        this.company?.documents?.length &&
        this.company.documents.some(x => x.document_type === 'FATURAMENTO' || x.document_type === 'BALANCO')
      ) {
        if(this.company.documents.some(x => (x.document_type === 'FATURAMENTO' || x.document_type === 'BALANCO') && x.status === 'DISAPPROVED')) {
          const disapproved_documents = this.company.documents.filter(x => (x.document_type === 'FATURAMENTO' || x.document_type === 'BALANCO') && x.status === 'DISAPPROVED')
          return disapproved_documents
        } else return []
      } else return []
    },
    getFinancialDocumentTypeSend() {
      if (this.company?.documents?.length) {
        if(this.company.documents.some(x => x.document_type === 'FATURAMENTO')) {
          return 'FATURAMENTO'
        } else if(this.company.documents.some(x => x.document_type === 'BALANCO')) {
          return 'BALANCO'
        }
      } else return ''
    },
    isWebView() {
      return window.isWebView == true;
    },
  },
  async created() {
    if (this.$store.getters.setPanel) {
      this.$router.push("/dashboard");
    }
    this.fetchUserSettings();
    await this.$store.dispatch("fetchFlags");
  },
  methods: {
    nameValidationSteps(name){
      const validations_name = [
        { step: this.$t('views.seller.validation_account_type.text_2'), validated: /[A-Za-z]\s[A-Za-z]/.test(name) }
      ];
      return validations_name ;
    },
    formatName(path) {
      let user = this.user
      let text = user[path]
      if (!text) {
        return;
      }
      text = text.replace(/\d/g, ''); // Apenas letras
      text = text.replace(/\s+/g, ' ').trim(); // Sem espaços duplos
      text = text.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase()); // Iniciais em maiúsculo

      user[path] = text
      this.user = {...user}

      if (text.split(' ').length <= 1) {
        this.$bvToast.toast(this.$t('views.seller.my_account.text_1569_2'), {
          title: this.$t('views.seller.my_account.text_1198'),
          variant: 'danger',
          autoHideDelay: 5000,
          appendToast: true
        })
      }
    },
    newValueReturnSwitch(value) {
      this.user.is_politically_exposed_person = value
    },
    removeSocialContractToEdit() {
      if (
        this.company?.documents?.length &&
        this.company.documents.some(x => x.document_type === 'CONTRATO_SOCIAL' || x.document_type === null)
      ) {
        this.company.documents = this.company.documents.filter(x => !(x.document_type === 'CONTRATO_SOCIAL' || x.document_type === null))
      }
    },
    removeFinancialDocumentToEdit() {
      if (
        this.company?.documents?.length &&
        this.company.documents.some(x => x.document_type === 'FATURAMENTO' || x.document_type === 'BALANCO')
      ) {
        this.financial_document_type = this.getFinancialDocumentTypeSend
        this.company.documents = this.company.documents.filter(x => !(x.document_type === 'FATURAMENTO' || x.document_type === 'BALANCO'))
      }
    },
    changeCountry(code) {
      this.country = code;
    },
    changeCountryEmpresa(code) {
      this.countryEmpresa = code.locale
      this.company.country = code.locale
      this.company.country_phone = code.locale
      this.company.support_country_phone = code.locale
    },
    changeCountryPessoal(code) {
      this.countryPessoal = code.locale;
      this.user.country_phone = code.locale;
    },
    openValidation() {
      if (this.isWebView) {
        if (this.getFlags.includes("idwall")) {
          window.flutter_inappwebview.callHandler('completeCheck');
        }else {
          this.$bvModal.show("identity-validation");
        }
      } else {
        if (this.getFlags.includes("idwall")) {
          this.$bvModal.show("user-validation");
        } else {
          this.$bvModal.show("identity-validation");
        }
      }
    },
    acessarContaCorrente() {
      this.$router.push("extrato/?withdraw=true");
    },
    openAndamentoDocs(docs) {
      this.andamento = docs;
      this.$bvModal.show("andamento_docs");
    },
    checkStatus(docs, status) {
      if (docs.length) {
        return docs.filter((item) => item.status == status);
      } else return [];
    },
    openResetSenha() {
      this.$bvModal.show("reset_password");
    },
    deleteAccount() {
      if(this.isWebView) {
        window.flutter_inappwebview.callHandler('deleteAccount');
      }
    },
    // foto usuario
    uploadImageSuccess(formData, index, fileList) {
      this.foto_user = fileList;
      this.help_foto = fileList;
    },
    beforeRemove(index, done, fileList) {
      var r = confirm(this.$t("views.seller.my_account.text_1606"));
      if (r == true) {
        done();
        this.foto_user.splice(index, 1);
        this.help_foto.splice(index, 1);
      }
    },
    editImage(formData, index, fileList) {
      this.foto_user = fileList;
      this.help_foto = fileList;
    },

    // documento usuario
    uploadDocumentSuccess(formData, index, fileList) {
      this.document_user = fileList;
    },
    beforeRemoveDocument(index, done, fileList) {
      var r = confirm(this.$t("views.seller.my_account.text_1196"));
      if (r == true) {
        done();
        this.document_user.splice(index, 1);
      }
    },
    editDocument(formData, index, fileList) {
      this.document_user = fileList;
    },
    buildFormData(formData, data, parentKey) {
      if (
        data &&
        typeof data === "object" &&
        !(data instanceof Date) &&
        !(data instanceof File)
      ) {
        Object.keys(data).forEach((key) => {
          this.buildFormData(
            formData,
            data[key],
            parentKey ? `${parentKey}[${key}]` : key
          );
        });
      } else {
        const value = data == null ? "" : data;

        formData.append(parentKey, value);
      }
    },
    jsonToFormData(data) {
      const formData = new FormData();

      this.buildFormData(formData, data);
      return formData;
    },
    onSubmit(tipo) {
      if(tipo === 'user' && (this.showNameValid || !this.user.name)){
        this.$bvToast.toast(this.$t('views.seller.my_account.text_1569_2'), {
          title: this.$t('views.seller.my_account.text_1198'),
          variant: 'danger',
          autoHideDelay: 5000,
          appendToast: true
        })
        return
      }
      if(tipo === 'user' && (this.showMotherNameValid || !this.user.mother_name)){
        this.$bvToast.toast(this.$t('views.seller.my_account.text_1569_3'), {
          title: this.$t('views.seller.my_account.text_1198'),
          variant: 'danger',
          autoHideDelay: 5000,
          appendToast: true
        })
        return
      }
      if(tipo === 'user' && (this.showCpf_CnpjValid || !this.user.cpf_cnpj)){
        this.$bvToast.toast(this.$t('views.seller.my_account.text_1252'), {
          title: this.$t('views.seller.my_account.text_1198'),
          variant: 'danger',
          autoHideDelay: 5000,
          appendToast: true
        })
        return
      }
      if(tipo === 'user' && (this.showZipCodeValid || !this.address_user.zip_code)){
        this.$bvToast.toast(this.$t('seller.address.listagem_enderecos.fill_postal_code_2'), {
          title: this.$t('views.seller.my_account.text_1168'),
          variant: 'danger',
          autoHideDelay: 5000,
          appendToast: true
        })
        return
      }

      this.submit = true;
      this.loading = true;

      let validaItem = true
      if (tipo === 'user' && !this.user.identity_validation.length) {
        validaItem = this.document_user ? true : false
      } else if (tipo === 'company' && !this.company.documents.length) {
        this.cpnjIsValid = validateCNPJ(this.company.cnpj)
        const validSocialContract = this.hasSocialContract ? true : !!this.contract_user
        validaItem = validSocialContract 
      }

      this.$validator.validateAll(tipo).then(async (result) => {
        if (result && validaItem) {
          let copyObject = { id: this.user.id, company: {} };
          let data = Object.assign({}, copyObject);
          if (tipo === "user") {
            let fix_date = this.user.birth_date
              .split("/")
              .reverse()
              .join("-");

            this.user.birth_date = fix_date;

            data = this.user;
            data.address = this.address_user;
            data.image = this.foto_user[0];
            data.country = this.countryPessoal;

            delete data.company;
          } else if (tipo === "company") {
            let copyCompany = Object.assign({}, this.company);
            data.company = copyCompany
            data.company.country = copyCompany.country
            if (
              (this.userLogged.company &&
                this.userLogged.company.documents.length > 0 &&
                this.userLogged.company.documents.every(
                  (x) => x.status !== "DISAPPROVED"
                )) ||
              (this.userLogged.company &&
                this.company.cnpj === this.userLogged.company.cnpj)
            ) {
              delete data.company.cnpj;
              delete data.company.document_type;
            }

            if (data.country === "BR") data.company.document_type = "CNPJ";
            else {
              if (data.company.document_type) {
                data.company.document_type = data.company.document_type.toUpperCase();
              } else {
                data.company.document_type = null;
              }
            }

            // Documents
            data.company.documents = []
            if (this.changeContract) {
              data.company.documents = [{document: this.contract_user, document_type: 'CONTRATO_SOCIAL'}]
            }
            if (this.changeContractFinancial) {
              data.company.documents = [
                ...data.company.documents,
                {document: this.contract_financial_user, document_type: this.financial_document_type}
              ]
            }

            // Telephone
            if(!this.whatsappEmpresa || this.whatsappEmpresa === '') {
              data.company.telephone = null
              data.company.country_phone = null
            }
            if (
              !this.whatsappEmpresaSupport ||
              this.whatsappEmpresaSupport === ""
            ) {
              data.company.support_telephone = null;
              data.company.support_country_phone = null;
            }
          }

          if(tipo === 'user' && this.user.customEmail === null){
            data.customEmail = this.$store.state.user.customEmail;
          }else{
            data.customEmail = this.user.customEmail;
          }

          data._method = "PUT";
          data.ignore_phone_validation = true;
          data = this.jsonToFormData(data);

          serviceUser
            .create(data, this.user.id || this.userId || JSON.parse(localStorage.user).id)
            .then(() => {
              // resetando coockie
              this.initDados();

              if (tipo === "user") {
                // this.user.documents = [{ status: "REQUESTED" }];
              } else if (tipo === "company") {
                this.company.documents = [{ status: "REQUESTED" }];
              }

              this.$bvToast.toast(
                this.$t("views.seller.my_account.text_1197"),
                {
                  title: this.$t("views.seller.my_account.text_1198"),
                  variant: "success",
                  autoHideDelay: 5000,
                  appendToast: true,
                }
              );

              if (this.isWebView) {
                window.flutter_inappwebview.callHandler("changeBirthday", {
                  birthday: this.user.birth_date,
                });
              }
            })
            .catch((err) => {
              if (tipo === 'company') {
                this.company.contract = null
                this.company.telephone = null
              }
              console.error(err);
            })
            .finally(() => {
              this.loading = false;
              this.submit = false;
            });
        } else {
          this.loading = false;
          this.$bvToast.toast(this.$t("views.seller.my_account.text_1199"), {
            title: this.$t("views.seller.my_account.text_1198"),
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          });
        }
      });
    },
    preencherCep(tipo) {
      let cep = "";
      if (tipo == "empresa") {
        cep = this.company.zip_code.replace(/\D/g, "");
      } else if (tipo == "usuario") {
        cep = this.address_user.zip_code.replace(/\D/g, "");
      }
      if (cep.length === 8) {
        fetch(`https://viacep.com.br/ws/${cep}/json/`)
          .then((response) => response.json())
          .then((response) => {
            if (tipo == "empresa") {
              this.company.street = response.logradouro;
              this.company.neighborhood = response.bairro;
              this.company.city = response.localidade;
              this.company.state = response.uf;
              this.company.complement = response.complemento;
              if (document.querySelector('#num_company')) {
                document.querySelector('#num_company').focus();
              }
            } else {
              this.address_user.street = response.logradouro;
              this.address_user.neighborhood = response.bairro;
              this.address_user.city = response.localidade;
              this.address_user.state = response.uf;
              this.address_user.complement = response.complemento;
              if (document.querySelector('#num')) {
                document.querySelector('#num').focus();
              }
            }
          });
      }
    },
    getStatusValidation(id) {
      serviceValidation
        .read({ id: "status" })
        .then((response) => {
          this.status_validation = response;
          if (!this.status_validation.status) {
            this.status_validation = {
              status: "INITIATED",
              observation: "",
            };
          }
        })
        .catch((err) => console.error(err));
    },
   async setDados() {
      this.switchIsLoaded = false
      this.getStatusValidation(this.userId);
      await this.getFlowValidation();
      this.user.id = this.dados_user.id;
      this.user.name = this.dados_user.name;
      this.user.cellphone = this.dados_user.cellphone;
      this.user.country_phone = this.dados_user.country_phone;
      this.user.rg = this.dados_user.rg;
      this.user.email = this.dados_user.email;
      this.user.cpf_cnpj = this.dados_user.cpf_cnpj;
      this.user.document_type = this.dados_user.document_type;
      this.user.image = this.dados_user.image;
      this.user.social_name = this.dados_user.social_name;
      this.user.documents = this.dados_user.documents || [];
      this.user.identity_validation = this.dados_user.identity_validation || [];
      this.isChecked = this.dados_user.is_politically_exposed_person === 'true' ? true : false;
      this.switchIsLoaded = true
      delete this.user.new_password;
      delete this.user.old_password;

      if (this.dados_user && this.dados_user.country) {
        this.countryCodePessoal = {
          country_code: this.dados_user.country.country_code,
          name: this.dados_user.country.name,
        };
      } else if (this.userLogged && this.userLogged.country) {
        this.countryCodePessoal = {
          country_code: this.userLogged.country.country_code,
          name: this.userLogged.country.name,
        };
      } else {
        this.countryCodePessoal = {
          country_code: this.isHeaven ? "US" : "BR",
          name: this.isHeaven ? "United States" : "Brasil",
        };
      }
      if (this.dados_user && this.dados_user.country) {
        this.countryPessoal = this.dados_user.country.country_code;
      } else {
        this.countryPessoal = "BR";
      }

      if (this.dados_user.cellphone) this.whatsapp = this.dados_user.cellphone;

      this.document_user = this.user.identity_validation;

      if (this.user.image) this.help_foto = [{ path: this.user.image }];

      this.address_user = Object.assign({}, this.dados_user.address || {
        zip_code: '',
        street: '',
        number: '',
        neighborhood: '',
        city: '',
        state: '',
        complement: ''
      })

      let dados_company = Object.assign({}, this.dados_user.company);
    
      this.company = {
        contract: dados_company.contract,
        name: dados_company.name,
        fantasy_name: dados_company.fantasy_name,
        telephone: dados_company.telephone,
        country_phone: dados_company.country_phone,
        support_telephone: dados_company.support_telephone,
        support_country_phone: dados_company.support_country_phone,
        cnpj: dados_company.cnpj,
        document_type: dados_company.document_type,
        email: dados_company.email,
        zip_code: dados_company.zip_code,
        street: dados_company.street,
        number: dados_company.number,
        neighborhood: dados_company.neighborhood,
        city: dados_company.city,
        state: dados_company.state,
        documents: dados_company.documents || [],
        disabled: dados_company.disabled,
      };
      if (dados_company && dados_company.country) {
        this.countryCodeEmpresa = {
          country_code: dados_company.country.country_code,
          name: dados_company.name,
        };
        this.countryEmpresa = dados_company.country.country_code
        this.company.country = dados_company.country.country_code
      } else {
        this.countryCodeEmpresa = {
          country_code: "BR",
          name: dados_company.name,
        };
        this.countryEmpresa = "BR"
        this.company.country = "BR"
      }
      this.loadingPhone = false;

      if (dados_company.telephone)
        this.whatsappEmpresa = dados_company.telephone;
      if (dados_company.support_telephone)
        this.whatsappEmpresaSupport = dados_company.support_telephone;

      this.loading = false;
    },
    initDados() {
      this.loading = true;
      this.$store
        .dispatch("userRequest")
        .then((response) => {
          if (response) {
            this.dados_user = Object.assign({}, { 
              ...response, 
              birth_date: response.birth_date ? moment(response.birth_date).format("DD/MM/YYYY") : null
            });
          } else {
            this.dados_user = Object.assign({}, this.$store.state.user.user);
          }
        })
        .then(() => {
          this.setDados();
        })
        .catch((err) => {
          console.error(err);
          this.dados_user = Object.assign({}, this.$store.state.user.user);
          this.setDados();
        })
        .finally(() => {
          this.loadingPhone = false;
          this.loading = false;
        });
    },
    showCountryList(component) {
      switch (component) {
        case "global":
          this.showCountryListGlobal = true;
          this.showCountryListTab = false;
          break;
        case "tab":
          this.showCountryListTab = true;
          this.showCountryListGlobal = false;
          break;
        default:
          break;
      }
    },
    onUpdatePessoal(payload) {
      if (payload.phoneNumber) {
        this.user.cellphone = `+${payload.countryCallingCode +
          payload.phoneNumber
            .replace(" ", "")
            .replace("-", "")
            .replace("(", "")
            .replace(")", "")}`;
        this.user.country_phone = payload.countryCode;
      }
    },
    onUpdateEmpresa(payload) {
      if (payload.phoneNumber) {
        this.company.telephone = `+${payload.countryCallingCode +
          payload.phoneNumber
            .replace(" ", "")
            .replace("-", "")
            .replace("(", "")
            .replace(")", "")}`;
        this.company.country_phone = payload.countryCode;
      }
    },
    onUpdateEmpresaSupport(payload) {
      if (payload.phoneNumber) {
        this.company.support_telephone = `+${payload.countryCallingCode +
          payload.phoneNumber
            .replace(" ", "")
            .replace("-", "")
            .replace("(", "")
            .replace(")", "")}`;
        this.company.support_country_phone = payload.countryCode;
      }
    },
    fetchUserSettings() {
      serviceUserSettings
        .read({ id: this.userId || JSON.parse(localStorage.user).id })
        .then((response) => {
          if (response.some((x) => x.key === "CHARGEBACK_EMAIL")) {
            const chargebackEmail = response.find(
              (x) => x.key === "CHARGEBACK_EMAIL"
            );
            this.user.customEmail = chargebackEmail.value;
          } else {
            this.user.customEmail = null;
          }

          if (response.some((x) => x.key === "ENRICHED_DATA")) {
            const key = response.filter(item => item.key === "ENRICHED_DATA").pop();
            this.user.enriched_data = key.value;
          }
          if (response.some((x) => x.key === "BIRTH_DATE")) {
            const key = response.filter(item => item.key === "BIRTH_DATE").pop();
            this.user.birth_date = key.value;
          }
          if (response.some((x) => x.key === "MOTHER_NAME")) {
            const key = response.filter(item => item.key === "MOTHER_NAME").pop();
            this.user.mother_name = key.value;
          }
          if (response.some((x) => x.key === "IS_POLITICALLY_EXPOSED_PERSON")) {
            const key = response.filter(item => item.key === "IS_POLITICALLY_EXPOSED_PERSON").pop();
            this.user.is_politically_exposed_person = key.value === "true" ? true : false;
            this.isChecked = key.value === 'true' ? true : false
          }
        })
        .catch((err) => console.error(err))
        .finally(() => this.switchIsLoaded = true );
    },
    updateCnpjIsValid(cnpj) {
      this.cpnjIsValid = validateCNPJ(cnpj)
    },

    // async getFlowValidation() {
    //   this.loading = true

    //   await serviceValidation
    //     .read('status-flow?flows=basic,first_product')
    //     .then(res => {
    //       this.flowsAreValid = res.flowsValidation;
    //     })
    //     .catch(err => console.error(err))
    //     .finally(() => this.loading = false)
    // },
  },
  mounted() {
    this.loadingPhone = true;
    this.initDados();
  },
};
</script>

<style lang="scss">
.name_valid{
  color: green;
}
.name_invalid{
  color: #dc3545;
}
.greenn_user_id_container {
  display: flex;
  width: 100%;
}
.greenn_user_id {
  height: 45px;
  padding-left: 28px !important;
  padding-right: 28px !important;
  background: #f7f7f7;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: #1e2024 !important;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.country-selector__toggle {
  padding-top: 5px !important;
}

.country-selector__input,
.input-tel__input {
  padding-top: 25px !important;
  padding-left: 40px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  letter-spacing: 0.01em !important;
  font-size: 14px !important;
  line-height: 1.5 !important;
  font-family: "Montserrat", sans-serif !important;
  font-variant-ligatures: normal !important;
  font-variant-caps: normal !important;
  font-variant-numeric: normal !important;
  font-variant-east-asian: normal !important;
}

.country-selector__label,
.input-tel__label {
  font-size: 11px !important;
  font-weight: normal !important;
  color: #333333 !important;
  line-height: 1.333rem;
  font-family: "Montserrat", sans-serif !important;
}
</style>

<style scoped lang="scss">
.info-checkbox {
  margin: 0;
  margin-top: 20px;
  margin-left: 5px;
  font-weight: 500;
}
.descricao-checkbox {
  display: block;
  margin-top: 5px;
  font-weight: normal;
  color: #81858e;
  font-size: 13px;
  max-width: 80%;
  line-height: 1.5;
}
h5 {
  font-weight: 600;
  font-size: 18px;
  color: $base-color;
}
h5 {
  color: $base-color-heaven;
}

.container-user {
  padding: 50px;
}
@media screen and (max-width: 768px) {
  .container-user {
    padding: 30px;
  }
}

.info-documento {
  margin: 0;
  font-weight: normal;
  color: #81858e;
  font-size: 13px;
  line-height: 1.5;
}

.info-documento + .info-documento {
  margin-top: 5px;
}
.btn-2 {
  color: #2133d2;
  background: rgba(33, 51, 210, 0.1);
  font-size: 12px;
  font-weight: 600;
  padding: 10px 15px;
  border-radius: 5px;
}
.aprovacao {
  display: flex;
  align-items: center;
}
.aprovacao img {
  margin-right: 10px;
}
.DISAPPROVED {
  background: rgba(255, 12, 55, 0.1);
  color: #ff0c37;
}
.APPROVED {
  background: rgba(0, 148, 136, 0.1);
  color: $base-color;
}
.APPROVED.heaven {
  background: $base-color-heaven-light;
  color: $base-color-heaven;
}
.link-aprovado {
  font-weight: 600;
  color: #2133d2;
}
.ver-arquivo {
  font-size: 13px;
  display: inline-block;
  padding: 5px;
}

.top-status {
  display: flex;
  align-items: center;
  gap: 30px;
}

.email-company {
  margin-top: -75px;
}
@media screen and (max-width: 768px) {
  .top-status {
    display: block;
  }
  .ver-arquivo {
    margin-bottom: 20px;
    margin-right: 20px;
  }
  .email-company {
    margin-top: initial;
  }
}
.form-control.heaven:hover {
  border-color: $base-color-heaven !important;
}
.have-account {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  line-height: 19.5px;
  text-align: left;
  color: #141414;
  padding-right: 20px;
}
.warning{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.switchFalse{
  margin-left: -45px;
}
.switchTrue{
  margin-left: -18px;
}
.card-validation {
  background: rgba(33, 51, 210, 0.1);
  border-radius: 5px;
  margin-bottom: 20px;
  padding: 30px;
  display: flex;
}

.card-validation p {
  color: #2133d2;
  font-size: 13px;
  line-height: 1.5;
}

.card-validation button {
  height: 40px !important;
  font-size: 13px !important;
  padding: 10px 15px !important;
  margin-top: 15px;
  text-align: right;
}

@media screen and (max-width: 768px) {  
  .card-validation {
    width: 100% !important;
    padding: 40px 20px;
    max-width: 100%;
  }
  }
</style>
