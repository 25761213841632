<template>
  <b-container fluid class="mt-1 container-user" v-if="!loading">
    <template v-for="(fee, index) in fees">
      <base-card bg="white" max-width="400px" :key="index">
        <header>
          <img
            :src="require(`@/assets/img/icons/${fee.icon}.svg`)"
            :alt="`${fee.icon} icon`"
            width="18px"
            height="18px"
          />
          <h1 v-html="fee.title" class="title"></h1>
        </header>
        <template v-if="fee.type === 'common'">
          <section
            class="content border-top-custom"
            v-for="(sub_fee, index) in fee.fees"
            :key="index"
          >
            <section class="infos">
              <h2 class="content__title" v-html="sub_fee.title"></h2>
              <p class="content__content" v-html="sub_fee.content"></p>
            </section>
            <span
              class="content__percentage"
              v-html="sub_fee.percentage"
              v-b-tooltip="{ title: sub_fee.tooltip_text, placement: 'right' }"
            ></span>
          </section>
        </template>
        <template v-else>
          <section
            class="content__product border-top-custom"
            v-for="(product, index) in fee.products"
            :key="index"
          >
            <h2 class="content__product__title" v-html="product.name"></h2>
            <section
              v-for="(sub_fee, index) in product.fees"
              :key="index"
              class="content__product__fees"
            >
              <p>
                {{ sub_fee.title }}
              </p>
              <p
                v-b-tooltip="{
                  title: sub_fee.tooltip_text,
                  placement: 'right',
                }"
              >
                {{ sub_fee.percentage }}
              </p>
            </section>
          </section>
        </template>
      </base-card>
    </template>
  </b-container>
  <div
    key="loading"
    v-else
    class="py-5 my-5 d-flex justify-content-center align-items-center"
  >
    <b-spinner variant="dark" label="Spinning"> </b-spinner>
    <p class="ml-4 info-documento">
      {{ $t('views.seller.my_account.text_1596') }}
    </p>
  </div>
</template>

<script>
// Components
import BaseCard from '@/components/shared/Card.vue';
// Services
import MyFeesService from '@/services/resources/MyFees.js';
const service = MyFeesService.build();
// Utils
import { EventBus } from '@/main.js';
import gtagSend from '@/utils/gtagSend';

export default {
  components: {
    BaseCard,
  },
  data() {
    return {
      fees: [],
      loading: false,
    };
  },
  methods: {
    async getFees() {
      this.fees = [];
      this.loading = true;
      await service
        .read('')
        .then((res) => {
          this.organizeFees(res);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    organizeFees(fees) {
      const aux = Object.keys(fees);
      aux.forEach((item) => {
        if (item === 'default') {
          this.hasDefaultFee(fees[item]);
        }
        if (item === 'product') {
          this.hasProductFee(fees[item]);
        }
        if (item === 'another') {
          this.hasAnotherFee(fees[item]);
        }
      });
    },
    hasDefaultFee(fees) {
      const default_fee = {
        title: this.$t('seller.my_account.my_fees.default_fee.title'),
        icon: 'star_blue',
        type: 'common',
        fees: [],
      };
      const aux = Object.keys(fees);
      aux.forEach((fee) => {
        switch (fee) {
          case 'seller_fee':
            if (!fees[fee].value) break;
            default_fee.fees.push({
              title: this.$t('seller.my_account.my_fees.seller_fee'),
              content: this.$t(
                'seller.my_account.my_fees.default_fee.seller_content'
              ),
              percentage: `${fees[fee].value}%`,
              tooltip_text: `${fees[fee].value}% + R$ 1`,
            });
            break;
          case 'affiliate_fee':
            if (!fees[fee].value) break;
            default_fee.fees.push({
              title: this.$t('seller.my_account.my_fees.affiliate_fee'),
              content: this.$t(
                'seller.my_account.my_fees.default_fee.affiliate_content'
              ),
              percentage: `${fees[fee].value}%`,
              tooltip_text: `${fees[fee].value}% + R$ 1`,
            });
            break;
          case 'international_fee':
            if (!fees[fee].value) break;
            default_fee.fees.push({
              title: this.$t('seller.my_account.my_fees.international_fee'),
              content: this.$t(
                'seller.my_account.my_fees.default_fee.international_content'
              ),
              percentage: `${fees[fee].value}%`,
              tooltip_text: `${fees[fee].value}% + $ 1`,
            });
            break;
          case 'high_ticket_fee':
            if (!fees[fee].value) break;
            default_fee.fees.push({
              title: this.$t('seller.my_account.my_fees.high_ticket_fee'),
              content: this.$t(
                'seller.my_account.my_fees.default_fee.high_ticket_content'
              ),
              percentage: `${fees[fee].value}%`,
              tooltip_text: `${fees[fee].value}% + $ 1`,
            });
            break;
        }
      });
      this.setFees(default_fee);
    },
    hasAnotherFee(fees) {
      const another_fees = {
        title: this.$t('seller.my_account.my_fees.another_fee.title'),
        icon: 'star_blue',
        type: 'common',
        fees: [],
      };
      const aux = Object.keys(fees);
      aux.forEach((fee) => {
        switch (fee) {
          case 'seller_fee':
            if (!fees[fee].value) break;
            another_fees.fees.push({
              title: this.$t('seller.my_account.my_fees.seller_fee'),
              content: this.$t(
                'seller.my_account.my_fees.another_fee.seller_content'
              ),
              percentage: `${fees[fee].value}%`,
            });
            break;
          case 'affiliate_fee':
            if (!fees[fee].value) break;
            another_fees.fees.push({
              title: this.$t('seller.my_account.my_fees.affiliate_fee'),
              content: this.$t(
                'seller.my_account.my_fees.another_fee.affiliate_content'
              ),
              percentage: `${fees[fee].value}%`,
            });
            break;
          case 'antecipation_fee':
            if (!fees[fee].value) break;
            another_fees.fees.push({
              title: this.$t('seller.my_account.my_fees.anticipation_fee'),
              content: this.$t(
                'seller.my_account.my_fees.another_fee.anticipation_content'
              ),
              percentage: `${fees[fee].value}%`,
            });
            break;
        }
      });
      this.setFees(another_fees);
    },
    hasProductFee(fees) {
      const product_fees = {
        title: this.$t('seller.my_account.my_fees.product_fee.title'),
        icon: 'product_blue',
        type: 'product',
        products: [],
      };
      fees.forEach((fee, index) => {
        const aux = Object.keys(fee);
        const aux_fees = [];
        aux.forEach((item) => {
          if (item.split('_').pop() === 'fee') {
            switch (item) {
              case 'seller_fee':
                if (!fee[item]) break;
                aux_fees.push({
                  title: this.$t('seller.my_account.my_fees.seller_fee'),
                  percentage: !!fee[item] ? `${fee[item]}%` : '',
                  tooltip_text: `${fee[item]}% + R$ 1`,
                });
                break;
              case 'affiliate_fee':
                if (!fee[item]) break;
                aux_fees.push({
                  title: this.$t('seller.my_account.my_fees.affiliate_fee'),
                  percentage: !!fee[item] ? `${fee[item]}%` : '',
                  tooltip_text: `${fee[item]}% + R$ 1`,
                });
                break;
              case 'high_ticket_fee':
                if (!fee[item]) break;
                aux_fees.push({
                  title: this.$t('seller.my_account.my_fees.high_ticket_fee'),
                  percentage: !!fee[item] ? `${fee[item]}%` : '',
                  tooltip_text: `${fee[item]}% + R$ 1`,
                });
                break;
            }
          }
        });
        product_fees.products.push({
          name: `#${index + 1} - ${fee.title}`,
          fees: aux_fees,
        });
      });
      this.setFees(product_fees);
    },
    setFees(item) {
      this.fees.push(item);
    },
    sendGtag(){
        gtagSend('minhas_taxas_acessadas ', {
            ID_Cliente: JSON.parse(localStorage.user).id || this.$store.getters.getProfile.id,
        });
    },
  },
  mounted() {
    this.sendGtag();
    this.getFees();
    EventBus.$on('atualizaPais', () => {
      this.getFees();
    });
  },
};
</script>

<style lang="scss" scoped>
.container-user {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}

.card {
  width: 100%;
  padding: 0;

  .border-top-custom {
    border-top: 1px solid #e5e5e5;
  }

  header {
    display: flex;
    gap: 15px;
    align-items: center;
    width: 100%;
    padding: 20px 25px;

    .title {
      color: #2133d2;
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
    }
  }

  .content {
    width: 100%;
    padding: 20px 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 30px;

    .infos {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    &__title {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #333333;
    }

    &__content {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 150%;
      color: #81858e;
    }

    &__percentage {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #333333;
      flex-wrap: nowrap;
      min-width: 70px;
      text-align: end;
    }
  }

  .content__product {
    @extend .content;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;

    &__title {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #333333;
    }

    &__fees {
      width: 100%;
      display: flex;
      justify-content: space-between;

      & p:first-child {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;

        color: #333333;
      }

      & p:last-child {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #333333;
      }
    }
  }
}
</style>
