<template>
  <BaseModal name="user-validation" size="md" :title="$t('seller.my_account.user_validation.text_305')">
    <b-overlay :show="loading" :hidden="clear" :disable="loading" rounded opacity="0.6" spinner-small
      spinner-variant="primary" class="d-inline-block">

      <span class="step-desc my-4" v-html="$t('seller.my_account.user_validation.title_deep')" v-if="step == 1"></span>

      <div class="downloads" v-if="step === 1">
        <div class="qrcode-container">
          <svg xmlns="http://www.w3.org/2000/svg" width="226" height="14" viewBox="0 0 226 14" fill="none">
            <path d="M221.485 12.728V4.24275H213" stroke="#009488" stroke-width="2" stroke-linecap="round"
              stroke-linejoin="round" />
            <path d="M12.728 4.24268H4.24275V12.728" stroke="#009488" stroke-width="2" stroke-linecap="round"
              stroke-linejoin="round" />
          </svg>
          <span v-html="qrcode"></span>
          <svg xmlns="http://www.w3.org/2000/svg" width="226" height="14" viewBox="0 0 226 14" fill="none">
            <path d="M4.24261 1V9.48528H12.7279" stroke="#009488" stroke-width="2" stroke-linecap="round"
              stroke-linejoin="round" />
            <path d="M213 9.48535H221.485V1.00007" stroke="#009488" stroke-width="2" stroke-linecap="round"
              stroke-linejoin="round" />
          </svg>
        </div>
      </div>

      <div class="success-container fade-in" v-else-if="step === 2">

        <div class="success-icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80" fill="none">
            <circle cx="40" cy="40" r="40" fill="#009488" />
            <path d="M50 33L36.25 46.75L30 40.5" stroke="white" stroke-width="4" stroke-linecap="round"
              stroke-linejoin="round" />
          </svg>
        </div>
        <div class="success-text">
          {{ $t('seller.my_account.user_validation.btn_step_success') }}
        </div>
      </div>
    </b-overlay>
    <template v-slot:footer>
      <section class="footer-modal">
        <button class="button button-primary" @click="nextStep" v-if="step == 1">
          {{ $t('seller.my_account.user_validation.btn_end') }}
        </button>
      </section>
      <span></span>
    </template>
  </BaseModal>
</template>
<script>
import QrCodeMobileValidationService from '@/services/resources/QrCodeMobileValidationService'
const QrCodeValidation = QrCodeMobileValidationService.build();


export default {
  name: 'UserValidation',
  data() {
    return {
      loading: false,
      step: 1,
      qrcode: '',
    }
  },
  computed: {
    userLogged() {
      return this.$store.getters.getProfile;
    },
    userId() {
      return this.$store.getters.getProfile.id;
    },
    isHeaven() {
      return this.$store.getters["getIsHeaven"]
    },
    isWebView() {
      return window.isWebView == true;
    }
  },
  methods: {
    clear() {
      this.step = 1;
    },
    async initQrCode() {
      this.loading = true;

      await QrCodeValidation
        .search()
        .then((response) => {
          this.qrcode = atob(response.qrcode);
        })
        .catch(err => console.error(err))
        .finally(() => {
          this.loading = false;
        })
    },
    nextStep() {
      this.step++;
      if(this.step === 3) {
        setTimeout(() => {
          this.step++;
        }, 2050)
      }
    }
  },
  async mounted() {
    await this.initQrCode();
  },
};
</script>

<style lang="scss" scoped>

.divider-title {
  position: relative;
  height: 52px;

  display: flex;
  align-items: center;
  justify-content: center;

  &:before{
    content: '';
    height: 1px;
    width: 100%;
    background-color: #F7F7F7;
    position: absolute;
  }
  .qr {
    width: 51px;
    height: 51px;
    border-radius: 50%;
    background-color: #F7F7F7;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.qrcode-container {
  border-radius: 0.625rem;
  background: #F7F7F7;
  width: 15.625rem;
  height: 15.625rem;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  margin: 0 auto;
}

.fade-in {
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 1s;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.success-container {
  display: flex;
  flex-wrap: wrap;
}

.success-text {
  color: #81858E;
  text-align: center;
  font-family: Montserrat;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  width: 100%;
  max-width: 26.625rem;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 8rem;
}

.success-icon {
  width: 100%;
  display: flex;
  margin-top: 4rem;
  justify-content: center;
  margin-bottom: 2.6rem;
}

.step-button {
  border-radius: 0.625rem;
  background: #009488;
}

.step-desc {
  color: #141414;
  font-family: Montserrat;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  display: block;
  text-align: justify;
}

.step-progress-container {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1.25rem;

  .step {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    color: #81858E;
    text-align: right;
    font-family: Montserrat;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }

  .progress-bar {
    border-radius: 0.3125rem;
    background: #D9D9D9;
    width: 100%;
    height: 0.25rem;
    flex-shrink: 0;
    overflow: hidden;

    .fill {
      border-radius: 0.3125rem;
      background: #009488;
      height: 10px;
      display: block;
      -webkit-transition: width 2s ease;
      -moz-transition: width 2s ease;
      -o-transition: width 2s ease;
      transition: width 2s ease;
    }

    .step-1 {
      width: 2%;
    }

    .step-2 {
      width: 50%;
    }

    .step-3, .step-4 {
      width: 100%;
    }
  }
}

.downloads {
  display: flex;
  justify-content: space-between;
}

.qr-code-container::after {
  margin-left: 2rem;
}

.qr-code-container {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  border-radius: 0.625rem;
  border: 1px solid #E5E5E5;
  width: 47%;
  padding: 1.56rem;


  .store-icon {
    position: absolute;
    position: absolute;
    right: -12px;
    bottom: -12px;
  }

  .code-image-container {
    width: 100%;

    .qr-code {
      position: relative;
      left: -0.32rem;
      width: 6rem;
      height: 6rem;
    }
  }

  h2 {
    color: #000;
    font-family: Montserrat;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    width: 100%;
  }

  p {
    color: #81858E;
    font-family: Montserrat;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    width: 100%;
  }

  a {
    color: #009488;
    font-family: Montserrat;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    width: 100%;
    display: block;
    margin-top: 1.25rem;
    margin-bottom: 0.30rem;
  }
}

.form-group {
  position: relative;
}

.form-control.heaven:hover {
  border-color: $base-color-heaven !important;
}

.footer-modal {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>

<style scoped>
.modal-content .modal-footer {
  padding-top: 0px;
  display: flex;
  justify-content: center;
}

.modal-title {
  color: #141414;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
}

.button-primary {
  color: #fff;
  background: #009488;
}

.modal-content .modal-header {
  border-bottom: 1px solid #E5E5E5 !important;
}
</style>
