import ValidationService from "../services/resources/ValidationService";

const serviceValidation = ValidationService.build();

const IdWall = {
  computed: {
    isMobile() {
      return this.client.width <= 1219;
    },
    isHeaven() {
      return this.$store.getters["getIsHeaven"];
    },
    isClient() {
      return this.$store.getters.getProfile.type === "CLIENT";
    },
    userLoggedType() {
      return this.$store.getters.getProfile.type;
    },
    userLogged() {
      return this.$store.getters.getProfile;
    },
    /**
     * @description Verifica se a empresa possui os documentos
     * @returns {boolean}
     */
    hasCompanyDocuments() {
      return (
        this.userLogged &&
        this.userLogged.company &&
        this.userLogged.company.documents &&
        this.userLogged.company.documents.length &&
        this.userLogged.company.documents.some(x => x.document_type === 'CONTRATO_SOCIAL' || x.document_type === null)
      )
    },
    /**
     * @description Verifica se os documentos da empresa foram aprovados
     * @returns {boolean}
     */
    hasCompanyDocumentsApproved() {
      return (
        this.userLogged &&
        this.userLogged.company &&
        this.userLogged.company.documents &&
        this.userLogged.company.documents.length &&
        this.userLogged.company.documents.some(x => (x.document_type === 'CONTRATO_SOCIAL' || x.document_type === null) && x.status === 'APPROVED')
      )
    },
    /**
     * @description Verifica se a validação de identidade é valida
     * @returns {boolean}
     */
    statusValidationDisapproved() {
      return !this.flowBiometryIsValid;
    },
    /**
     * @description Verifica se o usuário está com a validação de identidade pendente
     * @returns {boolean}
     */
    isBiometryOk() {
      return this.flowBiometryExists && this.flowBiometryIsValid;
    },
    /**
     * @description Verifica se o usuário é cliente e se está com a validação de identidade pendente
     * @returns {boolean}
     */
    pendingIdentityValidation() {
      return !this.isClient && this.flowsAreValid && !this.isBiometryOk;
    },
    /**
     * @description Verifica se o usuário está com a validação de identidade pendente e se é uma conta PF para o mobile
     * @returns {boolean}
     */
    pendingIdentityValidationMobile() {
      return this.isMobile && this.pendingIdentityValidation;
    },
      /**
     * @description Verifica se o usuário está com a validação de identidade pendente e se é uma conta PF para o mobile
     * @returns {boolean}
     */
    pendingIdentityValidationNotMobile() {
      return !this.isMobile && this.pendingIdentityValidation;
    },
    /**
     * @description Verifica se o usuário está com a validação de identidade pendente e se é uma conta PJ
     * @returns {boolean}
     */
    pendingIdentityValidationCompany() {
      const basicCondition = !this.isClient && !this.isMobile && this.flowsAreValid;
      const accountTypeCondition = this.account_type === 'PJ' || this.account_type === 'PENDING_PJ';
      const userAccountCondition = basicCondition && !!this.userLogged.company_id && accountTypeCondition && !this.hasCompanyDocuments;
      return !this.pendingIdentityValidation && userAccountCondition;
    },
    /**
     * @description Verifica se o usuário está com a validação de identidade pendente e se é uma conta PJ para o Mobile
     * @returns {boolean}
     */
    pendingIdentityValidationCompanyMobile() {
      const basicCondition = !this.isClient && this.isMobile && this.flowsAreValid;
      const accountTypeCondition = this.account_type === 'PJ' || this.account_type === 'PENDING_PJ';
      const userAccountCondition = basicCondition && !!this.userLogged.company_id && accountTypeCondition && !this.hasCompanyDocuments;
      return !this.pendingIdentityValidationMobile && userAccountCondition;
    },
  },
  data() {
    return {
      loading: false,
      flowBiometryExists: false,
      flowBiometryIsValid: false,
      flowsAreValid: false,
    };
  },
  methods: {
    /**
     * @description Verifica se fluxos são validos e o estado da biometria
     * @returns {Promise<void>}
     */
    async getFlowValidation() {
      this.loading = true

      await serviceValidation
        .read('status-flow?flows=basic,first_product')
        .then(res => {
          this.flowsAreValid = res.flowsValidation;
          this.flowBiometryIsValid = res.flowBiometryIsValid;
          this.flowBiometryExists = res.flowBiometryExists;
        })
        .catch(err => console.error(err))
        .finally(() => this.loading = false)
    },
  }
}

export default IdWall
