export default (cnpj) => {
  if(!cnpj) return false
  cnpj = cnpj.replace(/\D/g, '')

  // Deve ter 14 dígitos
  if (cnpj.length !== 14) return false

  // Calcula o primeiro dígito verificador
  let soma = 0;
  let peso = 5;
  for (let i = 0; i < 12; i++) {
      soma += parseInt(cnpj[i]) * peso;
      peso--;
      if (peso < 2) {
          peso = 9;
      }
  }
  const digitoVerificador1 = (soma % 11 < 2) ? 0 : 11 - (soma % 11);

  // Calcula o segundo dígito verificador
  soma = 0;
  peso = 6;
  for (let i = 0; i < 13; i++) {
      soma += parseInt(cnpj[i]) * peso;
      peso--;
      if (peso < 2) {
          peso = 9;
      }
  }
  const digitoVerificador2 = (soma % 11 < 2) ? 0 : 11 - (soma % 11);

  // Verifica se os dígitos verificadores são válidos
  return parseInt(cnpj[12]) === digitoVerificador1 && parseInt(cnpj[13]) === digitoVerificador2;
}