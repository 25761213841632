<template>
  <div class="custom-switch-button" :class="{ 'checked': checked, 'disabled': disabled }" @click="toggleSwitch">
    <div class="switch-bg"></div>
    <div class="switch-text switch-text-left" :class="{ 'selected': !checked }">
      {{ $t('views.seller.my_account.text_1254') }} 
    </div>
    <div class="switch-text switch-text-right" :class="{ 'selected': checked }">
      {{ $t('views.seller.my_account.text_1255') }} 
    </div>
  </div>
</template>

<script>
export default {
  name: 'SwitchButton',
  props: {
    isChecked: {
      type: Boolean,
      default: false,
    },
    newValueReturn: {
      type: Function,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      checked: false
    }
  },
  methods: {
    init() {
      this.checked = this.isChecked
      this.newValueReturn(this.checked)
    },
    toggleSwitch() {
      if (!this.disabled) {
        this.checked = !this.checked;
        this.newValueReturn(this.checked);
      }
    },
  },
  mounted() {
    this.init()
  },
}
</script>

<style scoped lang="scss">
.custom-switch-button {
    position: relative;
    width: 134px;
    height: 30px;
    border-radius: 15px;
    background-color: #E5E5E5;
    cursor: pointer;
    overflow: hidden;
  }
  .switch-bg {
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    height: 100%;
    border-radius: 15px;
    background-color: #004392;
    transition: width 0.5s ease, right 0.5s ease;
  }
  .custom-switch-button.checked .switch-bg {
    width: 100%;
    right: 50%;
    border-radius: 15px;
    background-color: #D31408;
  }
  .switch-text {
    position: absolute;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    padding: 0 12px;
    color: #fff;
    font-family: Montserrat;
    font-size: 13px !important;;
    font-weight: 600;
    line-height: 15.85px;
  }
  .switch-text-left {
    left: 9px;
  }
  .switch-text-right {
    right: 8px;
  }
  .switch-text.selected {
    color: #000000;
  }
  .custom-switch-button.disabled {
    pointer-events: none;
    opacity: 0.6;
  }
</style>
