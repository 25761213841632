import Rest from '@/services/Rest';


/**
 * @typedef {Downloadervice}
 */
export default class Downloadervice extends Rest {
  /**
   * @type {String}
   */
  static resource = '/download'
}