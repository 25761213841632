<template>
  <BaseModal
    name="reset_password"
    size="md"
    :title="$t('seller.my_account.reset_password.text_305')"
    @shown="openModal"
    @hidden="clear"
  >
    <b-overlay
      :show="loading"
      :disable="loading"
      rounded
      opacity="0.6"
      spinner-small
      spinner-variant="primary"
      class="d-block pt-2 pb-2"
    >
      <b-form>
        <b-form-group
          :label="$t('seller.my_account.reset_password.text_306')"
          label-for="password"
        >
          <b-input-group>
            <b-form-input
              id="password"
              name="password"
              type="password"
              v-model="user.old_password"
              v-validate="'required'"
              :class="{'heaven': isHeaven}"
            ></b-form-input>
            <b-input-group-append v-if="user.old_password">
              <b-button
                class="px-2"
                ref="mostrar_senha"
                @click="mostrarSenha('password')"
                variant="light"
                >{{
                  $t("seller.my_account.reset_password.text_1855")
                }}</b-button
              >
            </b-input-group-append>
          </b-input-group>
          <b-form-invalid-feedback :state="!(errors.has('password') && submit)">
            {{ $t("seller.my_account.reset_password.text_307") }}
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          :label="$t('seller.my_account.reset_password.text_308')"
          label-for="new_password"
          class="mt-3"
        >
          <b-input-group>
            <b-form-input
              ref="password"
              id="new_password"
              name="new_password"
              type="password"
              v-validate="'strong_password'"
              v-model="user.new_password"
              :class="{'heaven': isHeaven}"
            ></b-form-input>
            <b-input-group-append v-if="user.new_password">
              <b-button
                class="px-2"
                ref="mostrar_nova_senha"
                @click="mostrarSenha('new_password')"
                variant="light"
              >
                {{ $t("seller.my_account.reset_password.text_1855") }}
              </b-button>
            </b-input-group-append>
          </b-input-group>
          <b-form-text>
            <password-validation
              :password="user.new_password"
            ></password-validation>
          </b-form-text>
          <b-form-invalid-feedback
            :state="!(errors.has('new_password') && submit)"
          >
            {{ $t("seller.my_account.reset_password.text_309") }}
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          :label="$t('views.seller.password_recovery.text_1475')"
          label-for="confirm_password"
          class="mt-3"
        >
          <b-input-group>
            <b-form-input
              id="confirm_password"
              placeholder="••••••••"
              v-model="confirm_password"
              :type="confirm_password_type"
              name="confirm_password"
              v-validate="'required|confirmed:password'"
              :class="{'heaven': isHeaven}"
            ></b-form-input>
            <b-input-group-append v-if="confirm_password">
              <b-button
                class="px-2"
                ref="mostrar_senha"
                @click="
                  confirm_password_type =
                    confirm_password_type == 'password' ? 'text' : 'password'
                "
                variant="light"
              >
                {{
                  confirm_password_type == "password"
                    ? $t("views.seller.register.text_1438")
                    : $t("views.seller.register.text_1451")
                }}</b-button
              >
            </b-input-group-append>
          </b-input-group>

          <b-form-invalid-feedback
            :state="submit ? !errors.has('confirm_password') : null"
          >
            {{ $t("views.seller.password_recovery.text_1261") }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-form>
    </b-overlay>

    <template v-slot:footer="{ cancel }">
      <BaseButton variant="link-info" class="mr-3" @click="cancel">
        {{ $t("seller.my_account.reset_password.text_1856") }}
      </BaseButton>
      <b-overlay
        :show="loading"
        :disable="loading"
        rounded
        opacity="0.6"
        spinner-small
        spinner-variant="primary"
        class="d-inline-block"
      >
        <BaseButton variant="black" @click="onSubmit">
          {{ $t("seller.my_account.reset_password.text_1857") }}
        </BaseButton>
      </b-overlay>
    </template>
  </BaseModal>
</template>
<script>
import UserService from "@/services/resources/UserService";
const serviceUser = UserService.build();

import PasswordValidation from "@/components/shared/PasswordValidation.vue";

export default {
  name: 'ResetPassword',
  components: { PasswordValidation },
  props: {
    user_id: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      showPassword: false,
      user: {
        id: -1,
        old_password: "",
        new_password: "",
      },
      confirm_password: "",
      confirm_password_type: "password",
      loading: false,
      submit: false,
    };
  },
  computed: {
    isHeaven() {
      return this.$store.getters["getIsHeaven"]
    }
  },
  methods: {
    mostrarSenha(seletor) {
      const input = document.querySelector(`#${seletor}`);
      this.showPassword = !this.showPassword;

      if (this.showPassword) {
        input.setAttribute("type", "text");
        if (seletor == "password") {
          this.$refs.mostrar_senha.innerText = this.$t(
            "seller.my_account.reset_password.text_1858"
          );
        } else {
          this.$refs.mostrar_nova_senha.innerText = this.$t(
            "seller.my_account.reset_password.text_1858"
          );
        }
      } else {
        input.setAttribute("type", "password");
        if (seletor == "password") {
          this.$refs.mostrar_senha.innerText = this.$t(
            "seller.my_account.reset_password.text_1855"
          );
        } else {
          this.$refs.mostrar_nova_senha.innerText = this.$t(
            "seller.my_account.reset_password.text_1855"
          );
        }
      }
    },
    onSubmit() {
      this.loading = true;
      this.submit = true;

      this.$validator.validateAll().then(async (result) => {
        if (result) {
          let data = this.user;

          serviceUser
            .update(data)
            .then(() => {
              this.$bvToast.toast(
                this.$t("seller.my_account.reset_password.text_310"),
                {
                  title: this.$t("seller.my_account.reset_password.text_1859"),
                  variant: "success",
                  autoHideDelay: 8000,
                  appendToast: true,
                }
              );
              this.clear();
              this.loading = false;
              this.submit = false;
              this.$bvModal.hide("reset_password");
              setTimeout(() => {
                this.$store.dispatch("logoutRequest");
              }, 8000);
            })
            .catch(() => {
              this.loading = false;
              this.submit = false;
            });
        } else {
          this.loading = false;
          this.$bvToast.toast(
            this.$t("seller.my_account.reset_password.text_311"),
            {
              title: this.$t("seller.my_account.reset_password.text_1859"),
              variant: "danger",
              autoHideDelay: 5000,
              appendToast: true,
            }
          );
        }
      });
    },
    clear() {
      this.user = {
        id: -1,
        old_password: "",
        new_password: "",
      };
    },
    openModal() {
      this.user.id = this.user_id;
    },
  },
  mounted() {
    this.user.id = this.id;
  },
};
</script>

<style lang="scss" scoped>
.form-group {
  position: relative;
}
.mostrar-senha {
  font-size: 13px;
  position: absolute;
  top: 55%;
  right: 20px;
  cursor: pointer;
  color: #81858e;
}

.form-control.heaven:hover {
  border-color: $base-color-heaven !important;
}
</style>
